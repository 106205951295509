import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Paragraph from '../components/blocks/Paragraph';
import PageTitle from '../components/blocks/PageTitle';

const LinksPage = ({ data }) => {

    return (
        <Layout>
            <HelmetDatoCms seo={data.datoCmsLinksPage.seoMetaTags}></HelmetDatoCms>
            <div className='py-lg space-y-md text-text'>
                <PageTitle>Linki</PageTitle>
                <Paragraph>
                    Sprawdź także na <a className="font-mono text-lg tracking-wide text-primary hover:underline" href="https://archiwum.allegro.pl/oferta/mada-chropowato-ep-12-winyl-pre-order-i7683534485.html">allegro.pl</a>
                </Paragraph>
                <Paragraph>oraz na <a className="font-mono text-lg tracking-wide text-primary hover:underline" href="https://madawwa.bandcamp.com/album/mada-chropowato-ep?fbclid=IwAR2dtOPk4sNIQLZNt-eDutzQHpwoXhEt3P0wMdAAbPJEjiIioZba-2AZizA">bandcamp.com</a></Paragraph>
                <Link className="button-main" to="/">Home</Link>
            </div>
        </Layout>
    )
};

export default LinksPage;

export const query = graphql`
    query LinksPage {
        datoCmsLinksPage {
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
        }
    }
`
